<!--
 * @Author: wyq
 * @Date: 2021-09-27 16:03:55
 * @LastEditTime: 2021-12-21 14:25:50
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\questionOptions.vue
-->
<template>
  <div ref="katex" class="question-options" :key="questionInfo.questionId">
    <div v-for="(item, index) in options" v-show="show" :key="index" class="option" :class="{
      'option-current': currentOption && currentOption.findIndex(i => i == index) != -1 && !disabled,
      'option-success': item.successOption && !hideAnswer,
      'option-error': item.errorOption && !hideAnswer
    }" @click="changeAnswer(index)">
      <span class="option-label">
        {{
            String.fromCharCode(64 + parseInt(index + 1))
        }}
      </span>
      <span>{{ item.value }}</span>
      <img v-for="i in item.imageUrl" :key="i.label" :src="i.url" @click.stop="previewImg(i.url)" />
    </div>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-img :src="previewImage"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    questionInfo: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    hideAnswer: {
      type: Boolean,
      default: false,
    },
    currentOption: Array,
  },
  data() {
    return {
      show: false,
      options: [],
      dialog: false,
      previewImage: ''
    }
  },
  watch: {
    'questionInfo.questionId': {
      handler() {
        this.show = false
        setTimeout(() => {
          this.$formula(this.$refs['katex'])
          setTimeout(() => {
            this.show = true
          }, 100)
        }, 100)
        this.options = this.questionInfo.options;
        const { type, responseStatus, myAnswer, arrAnswer } = this.questionInfo;
        if (myAnswer !== undefined) {
          if (type == 1) {
            if (responseStatus == 1) {
              this.$set(this.options[myAnswer], 'successOption', true);
            } else {
              this.$set(this.options[myAnswer], 'errorOption', true);
            }
            console.log(type, responseStatus, this.options[myAnswer]);
          } else if (type == 4) {
            myAnswer.map(i => {
              let flag = arrAnswer.find(anserItem => anserItem == i);
              if (flag) {
                this.$set(this.options[i], 'successOption', true);
              } else {
                this.$set(this.options[i], 'errorOption', true);
              }
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeAnswer(index) {
      if (this.questionInfo.type == 1) {
        this.$emit('update:currentOption', [index]);
      } else if (this.questionInfo.type == 4) {
        let result = this.currentOption ? this.currentOption : [];
        const findIndex = result.findIndex(i => i == index);
        if (findIndex == -1) {
          result.push(index);
        } else {
          result.splice(findIndex, 1);
        }
        this.$emit('update:currentOption', result);
      }
    },
    previewImg(url) {
      this.previewImage = url;
      this.dialog = true
    },
  },
}
</script>
<style lang="scss" scoped>
.question-options {
  .option {
    display: flex;
    align-items: center;
    font-size: 20px;
    white-space: pre-wrap;

    &+.option {
      margin-top: 30px;
    }

    img {
      width: 100px;
      height: auto;
    }

    .option-label {
      min-width: 36px;
      height: 36px;
      margin-right: 24px;
      color: #666666;
      text-align: center;
      line-height: 34px;
      border: 1px solid #999999;
      border-radius: 14px;
    }

    &-current {
      .option-label {
        color: #fff;
        border-color: #0148ff;
        background-color: #0148ff;
      }
    }

    &-success {
      .option-label {
        color: #fff;
        border-color: #52c41a;
        background-color: #52c41a;
      }
    }

    &-error {
      .option-label {
        color: #fff;
        border-color: #ff6262;
        background-color: #ff6262;
      }
    }
  }
}
</style>
