<!--
 * @Author: wyq
 * @Date: 2021-09-27 16:03:55
 * @LastEditTime: 2022-04-15 10:30:49
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\questionStem.vue
-->
<template>
  <div ref="katex" class="question-stem" :key="questionInfo.questionId">
    <div class="title" v-show="show">{{ questionInfo.title }}</div>
    <div
      v-for="(item, index) in questionInfo.titleImages"
      :key="item.url"
      class="image"
    >
      <img
        :src="item.url"
        @click="previewImg(questionInfo.titleImages, index)"
      />
      <p>{{ item.label }}</p>
    </div>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-img :src="previewImage"> </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    questionInfo: Object,
  },
  watch: {
    "questionInfo.questionId": {
      handler() {
        this.show = false;
        setTimeout(() => {
          this.$formula(this.$refs["katex"]);
          setTimeout(() => {
            this.show = true;
          }, 100);
        }, 100);
      },
      immediate: true,
    },
  },
  data() {
    return {
      show: false,
      dialog:false,
      previewImage:null
    };
  },
  methods: {
    previewImg(img, index) {
      console.log(img[index].url);
      this.previewImage = img[index].url;
      this.dialog = true
    },
  },
};
</script>
<style lang="scss" scoped>
.question-stem {
  max-height: calc(100vh - 315px);
  overflow-y: auto;
  margin: 32px 0;
  .title {
    font-size: 20px;
    color: #333333;
    line-height: 37px;
    white-space: pre-wrap;
  }
  .image {
    display: inline-block;
    width: 150px;
    margin: 10px 0;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin: 0;
      text-align: center;
    }
    & + .image {
      margin-left: 20px;
    }
  }
}
</style>